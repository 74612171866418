<template>
  <video style="width: 100%; height: 100%" class="video-js videojs-default-skin" preload="auto">
    <source :src="url" />
  </video>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
export default {
  data() {
    return {
      player: null,
    };
  },
  props: {
    url: String,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 播放器初始化
      var that = this;
      let id = this.$el.id;
      this.$nextTick(() => {
        this.player = videojs(
          id,
          {
            //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
            controls: true,
            //自动播放属性,muted:静音播放
            autoplay: true,
            //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",

            textTrackDisplay: false,
            posterImage: true,
            errorDisplay: false,
            controlBar: {
              currentTimeDisplay: true,
              timeDivider: true,
              durationDisplay: false,
              remainingTimeDisplay: false,
              liveDisplay: true,
              progressControl: false,
              volumePanel: {
                inline: false,
              },
            },
            muted: true,
          },
          function () {
            this.play(); // 自动播放
          }
        );
        this.player.on('beforeerror', function (event) {
          that.url = '';
          let oldurl = that.url + '';
          setTimeout(() => {
            that.url = oldurl;
          }, 500);
          return null;
        });
        this.player.on('retryplaylist', function (event) {
          that.url = '';
          let oldurl = that.url + '';
          setTimeout(() => {
            that.url = oldurl;
          }, 500);
        });

      });
    },
  },
  // 离开页面销毁视频播放器
  beforeDestroy() {
    if (this.player != null) {
      this.player.dispose(); // dispose()会直接删除Dom元素
    }
  },
  watch: {
    url: function () {
      if (this.url) {
        document.getElementById(this.$el.id).play();
      }
    }
  },
};
</script>

<style lang="scss">
.videojs-default-skin.video-js {
  .vjs-big-play-button {
    font-size: 2.5em;
    line-height: 2.3em;
    height: 2.5em;
    width: 2.5em;
    -webkit-border-radius: 2.5em;
    -moz-border-radius: 2.5em;
    border-radius: 2.5em;
    background-color: #73859f;
    background-color: rgba(115, 133, 159, 0.5);
    border-width: 0.15em;
    left: 50%;
    top: 50%;
    margin-top: -1.25em;
    margin-left: -1.25em;
  }
}

.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}
</style>